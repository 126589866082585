/**
 * Fichier environment unique
 *
 * Exporte une fonction loadEnvironment permettant de lancer une requête au back pour obtenir le bon environment,
 * en renvoyant une Promise, qui est utilisée dans main.ts pour délayer le boostrap d'Angular.
 *
 * Exporte une constante environment qui contiendra les variables récupérées du back + les variables statiques
 *
 * La variable staticEnvironment permet de définir des variables d'environnement statiques,
 * ne dépendant pas de l'environnement où est lancé l'app
 */

import { getDynamicEnvironment } from "./environment.dynamic";
import { Environment } from "./environment.interface";

/**
 * Variable environment, permet de conserver le même fonctionnment qu'avant dans Angular,
 * Mettre directement les variables statiques ici.
 */
export let environment: Partial<Environment> = {
	serviceClient: '09 70 70 77 01',
	mapboxToken: 'pk.eyJ1IjoiY29vcnR5IiwiYSI6ImNrNTUwc2NvdjA1aGczbnN4aWgzeDR2MmIifQ.iQ6K1QfI7ZmdfA24PWtUPg'
};

/**
 * Charge l'environnement depuis l'api et le fusionne avec l'environnement statique,
 * Assigne la variable environment
 * @returns Promise<Partial<Environment>>
 */
export async function loadEnvironment(): Promise<Partial<Environment>> {
	const dynamicEnvironment = await getDynamicEnvironment();
	const env = {
		...environment,
		...dynamicEnvironment
	};

	environment = env;

	return environment;
}


